import React from "react";
import { Container, Stack, Typography } from "@mui/material";
import theme from "../theme";
import FocusPage from "../components/FocusPage";
import { useBreakpoints } from "../hooks/useBreakpoint";

interface HomeProps {
  saleDates: string[];
}

const Home: React.FC<HomeProps> = ({ saleDates }) => {
  const { upMd } = useBreakpoints();

  return (
    <Container>
      <FocusPage title="Vítejte na našich stránkách">
        <Typography paragraph>
          Náš rodinný statek se nachází v obci Lipí u Náchoda. Od roku 2009 se zabýváme chovem masného skotu plemene
          Blonde d´Aquitane v kontrolovaném BIO režimu. Hospodaříme na cca 80 ha půdy v nadmořské výšce kolem 500m.
          Náplní naší činnosti je chov skotu a prodej bio masa.
          <br />
          <br />
          V roce 2012 jsme postavili nové zimoviště přímo v pastevním areálu.
          Zvířata mají během celého vegetativního období přístup na pastviny, klademe důraz na dostatek péče, způsob
          výživy, volnost pohybu, soulad s přírodou a především na pohodu zvířat, což mimo jiné znamená, že matky po
          otelení s telátky stráví celé léto na pastvině, na podzim jsou telata odstavena a krávy se chystají na další
          porod.
          Nechte se přesvědčit o kvalitě našeho soukromého zemědělství. Navštivte nás na adrese: Lipí 14, Náchod.
        </Typography>
        <Stack
          sx={{
            p: upMd ? "25px 40px" : "20px 20px",
            textAlign: "left",
            backgroundColor: theme.palette.secondary.dark,
            color: "white",
            borderRadius: "10px",
          }}
        >
          <Typography component="h1" variant="h5" mb="8px">
            Termíny prodeje
          </Typography>
          <Stack direction="row">
            <Stack pr={upMd ? "30px" : "10px"}>
              <Stack direction="row">

                <Typography>
                  Vždy v sobotu od 9:00 do 12:00. V termínech prodeje probíhá volný prodej steaků, smíšený balíček pouze
                  po
                  předchozím objednání.
                </Typography>
                {!upMd &&
                  <img
                    src={"/assets/icons/calendar.png"}
                    width="80px"
                    height="80px"
                    alt="calendar_icon"
                    style={{ marginLeft: "auto", marginRight: "0" }}
                  />
                }
              </Stack>
              <ul style={{ textAlign: "left" }}>
                {saleDates.map(date => (
                  <li key={date}>{date}</li>
                ))}
              </ul>
            </Stack>
            {upMd &&
              <img
                src={"/assets/icons/calendar.png"}
                width="160px"
                height="160px"
                alt="calendar_icon"
                style={{ marginLeft: "auto", marginRight: "0" }}
              />
            }
          </Stack>
        </Stack>
      </FocusPage>
    </Container>
  );
};

export default Home;
