import React, { useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { Box } from "@mui/material";
import theme from "./theme";
import Navbar from "./components/Navbar";
import MyCarousel from "./components/MyCarousel";
import Home from "./pages/Home";
import MeatPrice from "./pages/MeatPrice";
import Services from "./pages/Services";
import Gallery from "./pages/Gallery";
import Contact from "./pages/Contact";
import { Config } from "./model/Config";

function App () {
  const [config, setConfig] = useState<Config>();

  useEffect(() => {
    fetch("/config.json")
      .then((response) => response.json())
      .then((json) => setConfig(json))
      .catch((error) => console.error("Error fetching JSON:", error));
  }, []);

    console.log(config);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Box
          flexDirection="column"
          sx={{
            // backgroundImage: "url(/assets/1.jpg)",
            backgroundSize: "100%",
            background: "linear-gradient(45deg, rgba(226, 209, 195, 1) 40%, rgba(249, 242, 234, 1) 75%);",
            minHeight: "100vh",
            display: "flex"
          }}
        >
          <Navbar />
          <MyCarousel />
          {config &&
            <Routes>
              <Route path="/" element={<Home saleDates={config.saleDates} />} />
              <Route
                path="/meat-price"
                element={
                  <MeatPrice steaks={config.prices.steaks} entrails={config.prices.entrails} />
                }
              />
              <Route path="/services" element={<Services />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          }
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;