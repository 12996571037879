import * as React from "react";
import { ReactElement } from "react";
import { Box } from "@mui/material";
import { useBreakpoints } from "../hooks/useBreakpoint";

interface MeatPrizeItemProps {
  meatType: string;
  price: number;
  dots: number;
}

const MeatPrizeItem: React.FC<MeatPrizeItemProps> = ({ meatType, price, dots }): ReactElement => {
  const { upSm } = useBreakpoints();

  return (
    <Box>
      <Box style={{ float: "left" }}>
        {`${meatType}${".".repeat(upSm ? dots : dots - 12)} `}
      </Box>
      <Box sx={{ float: "right" }}>{`${price} Kč`}</Box>
    </Box>
  );
};

export default MeatPrizeItem;