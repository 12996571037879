import React from "react";
import { Container, List, Typography } from "@mui/material";
import ListItem from "../components/ListItem";
import theme from "../theme";
import FocusPage from "../components/FocusPage";

const Services: React.FC = () => {
  return (
    <Container>
      <FocusPage title="Služby">
        <List sx={{ marginTop: "-20px" }}>
          <ListItem>
            Nabízíme možnost mechanizovaného sečení trávy na pozemcích, odvoz a manipulaci s balíky sena/senáže.
          </ListItem>
          <ListItem>Dále nabízíme prodej zástavových jalovic a býčků do výkrmu.</ListItem>
          <ListItem>
            Prodáváme smíšené 10kg balíčky hovězího VYZRÁLÉHO masa. Balíček obsahuje různé druhy masa:
            přední, zadní, kližku, gulášové, žebra, kosti na polívku, steakové maso....
          </ListItem>
            <ListItem>
            Vše je vakuově balené, popsané, s uvedeným datem spotřeby a letákem informujícím o vlastnostech masa
            a možnostech zpracování.
            Objednávky můžete realizovat na uvedeném telefonním čísle - viz kontakt.
          </ListItem>
        </List>
        <p style={{ textAlign: "center" }}>
          <b>
            Děláme vše pro Vaši plnou spokojenost, individuální domluva možná!
          </b>
        </p>
        <Typography
          sx={{
            p: "25px 20px",
            textAlign: "center",
            backgroundColor: theme.palette.secondary.dark,
            color: "white",
            borderRadius: "10px"
          }}
        >
          Pokud jste vlastníky pozemků a chcete, aby Vaše pozemky byly obhospodařovány v BIO režimu, s patřičnou
          péčí a s úctou ke generacím, které na nich pracovaly a měly k nim blízký vztah, neváhejte nás
          kontaktovat, bude nám velkou ctí starostlivě pečovat právě o Vaše pozemky!
        </Typography>
      </FocusPage>
    </Container>
  );
};

export default Services;