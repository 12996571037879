import React from "react";
import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import Button, { ButtonProps } from "@mui/material/Button";
import theme from "../theme";
import { useBreakpoints } from "../hooks/useBreakpoint";

interface NavButtonProps extends ButtonProps {
  to: string;
  component: React.ElementType;
}

const GradientAppBar = styled(AppBar)({
  background: "white",
});

const NavButton = styled(Button)<NavButtonProps>({
  margin: "0 3px",
  fontWeight: "bold",
  color: theme.palette.secondary.dark,
  "&:hover, &:focus": {
    backgroundColor: theme.palette.secondary.dark,
    color: "white"
  },
});

const Navbar: React.FC = () => {
  const { upLg, upMd } = useBreakpoints();
  const navigate = useNavigate();

  return (
    <GradientAppBar position="static">
      <Toolbar>
        <img
          style={{
            cursor: "pointer",
            height: "42px",
            width: "42px",
          }}
          onClick={() => navigate("/")}
          src="/assets/icons/logo.jpg"
          alt="logo"
        />
        {upLg &&
          <Typography
            onClick={() => navigate("/")}
            variant="h5"
            sx={{
              cursor: "pointer",
              color: theme.palette.secondary.dark,
              marginLeft: "15px",
              flexGrow: 1,
              fontWeight: "bold",
            }}
          >RODINNÝ STATEK VÍTEK</Typography>
        }
        <Box sx={{ display: "flex", justifyContent: "right", marginRight: upLg ? "10px" : "0px", margin: upMd ? "auto" : "auto 0" }}>
          {upLg &&
            <NavButton component={Link} to="/">Úvod</NavButton>
          }
          <NavButton component={Link} sx={{marginRight: upLg ? "10px" : "0px" }} to="/meat-price">{upLg ? "Ceník masa" : "Ceník"}</NavButton>
          <NavButton component={Link} sx={{marginRight: upLg ? "10px" : "0px" }} to="/services">Služby</NavButton>
          <NavButton component={Link} sx={{marginRight: upLg ? "10px" : "0px" }} to="/gallery">Foto</NavButton>
          <NavButton component={Link} sx={{marginRight: upLg ? "10px" : "0px" }} to="/contact">Kontakt</NavButton>
        </Box>
      </Toolbar>
    </GradientAppBar>
  );
};

export default Navbar;