import React from "react";
import { Container, Stack, SxProps, Theme, Typography, useTheme } from "@mui/material";
import { useBreakpoints } from "../hooks/useBreakpoint";
import FocusPage from "../components/FocusPage";
import MeatPrizeItem from "../components/MeatPrizeItem";
import { Product } from "../model/Config";

const meatPrizeBoxStyles = (theme: Theme, upSm: boolean): SxProps<Theme> => ({
  width: upSm ? "340px" : "290",
  p: "25px 20px",
  margin: "0 auto 30px auto",
  textAlign: "center",
  backgroundColor: theme.palette.secondary.dark,
  color: "white",
  borderRadius: "10px"
});

interface MeatPriceProps {
  steaks: Product[];
  entrails: Product[];
}

const MeatPrice: React.FC<MeatPriceProps> = ({ steaks, entrails }) => {
  const { upLg, upSm } = useBreakpoints();
  const theme = useTheme();

  return (
    <Container>
      <FocusPage title={"Prodej masa & ceník"}>
        <Stack direction={upLg ? "row" : "column"}>

          <Stack sx={{ ...meatPrizeBoxStyles(theme, upSm) }}>
            <Typography variant="h5" component="h1" gutterBottom>
              Steaky
            </Typography>
            <Stack display="flex">
              {steaks.map((steak) => (
                <MeatPrizeItem
                  key={steak.name}
                  meatType={steak.name}
                  price={steak.price}
                  dots={steak.dots}
                />
              ))}
            </Stack>
          </Stack>

          <Stack sx={{ ...meatPrizeBoxStyles(theme, upSm) }}>
            <Typography variant="h5" component="h1" gutterBottom>
              Hovězí vnitřnosti
            </Typography>
            <Stack textAlign="left">
              {entrails.map((item) => (
                <MeatPrizeItem
                  key={item.name}
                  meatType={item.name}
                  price={item.price}
                  dots={item.dots}
                />
              ))}
            </Stack>
          </Stack>

        </Stack>
      </FocusPage>
    </Container>
  );
};

export default MeatPrice;