import React from "react";
import { Card, CardMedia, Container, Grid } from "@mui/material";
import FocusPage from "../components/FocusPage";

const Gallery: React.FC = () => {
  const images = [
    "/assets/gallery/2.jpg",
    "/assets/gallery/3.jpg",
    "/assets/gallery/5.jpg",
    "/assets/gallery/6.jpg",
  ];

  return (
    <Container>
      <FocusPage title="Fotogalerie">
        <Grid container spacing={3}>
          {images.map((image, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card>
                <CardMedia component="img" height="200" image={image} />
              </Card>
            </Grid>
          ))}
        </Grid>
      </FocusPage>
    </Container>
  );
};

export default Gallery;