import * as React from "react";
import { PropsWithChildren, ReactElement } from "react";

interface ListItemProps {
}

const ListItem: React.FC<PropsWithChildren<ListItemProps>> = ({ children }): ReactElement => {

  return (
    <li style={{display: "flex", margin: "10px 0"}}>
      <img src={"/assets/icons/checklist.png"} height={"24px"} width={"24px"} style={{marginRight: "20px", alignContent:"center"}} alt=""/>
      {children}
    </li>
  );
};

export default ListItem;