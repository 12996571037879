import React from "react";
import { Container, Stack, Typography } from "@mui/material";
import FocusPage from "../components/FocusPage";
import theme from "../theme";
import { useBreakpoints } from "../hooks/useBreakpoint";

const Contact: React.FC = () => {
  const { upMd, upSm } = useBreakpoints();
  const ICON_SIZE_SM = "50px";
  const ICON_SIZE_LG = "70px";

  return (
    <Container>
      <FocusPage title="Kontakt">
        <Stack
          sx={{
            p: upSm ? "25px 40px": "15px 15px",
            backgroundColor: theme.palette.secondary.dark,
            color: "white",
            borderRadius: "10px",
          }}
        >
          <Typography fontWeight="bold" component="h1" variant="h6" mb="20px">
            Rodinný statek Vítek
          </Typography>
          <Stack direction={upMd ? "row" : "column"} display="flex">

            <Stack direction="row" spacing={upMd ? 0 : "7px"} width="50%" >
              <img
                src="/assets/icons/phone.png"
                width={upMd ? ICON_SIZE_LG : ICON_SIZE_SM}
                height={upMd ? ICON_SIZE_LG : ICON_SIZE_SM}
                alt="calendar_icon"
                style={{ marginRight: "16px" }}
              />
              <Stack>
                <Stack direction="row">
                  <b>email:</b>
                  <span style={{ textAlign: "left", marginLeft: "25px" }}>
                  <a style={{ color: "white" }} href="mailto:va.vitek@seznam.cz">va.vitek@seznam.cz</a>
                </span>
                </Stack>
                <Stack direction="row">
                  <b>tel:</b>
                  <span style={{ textAlign: "left", marginLeft: "45px" }}>
                  <a style={{ color: "white" }} href="tel:+420604795935">+420 604 795 935</a>
                </span>
                </Stack>
                <Stack direction="row">
                  <b>IČO:</b>
                  <span style={{ textAlign: "left", marginLeft: "37px" }}>04583949</span>
                </Stack>
              </Stack>
            </Stack>

            <Stack sx={{ pt: upMd ? "0" : "16px", width:"50%", justifyContent: upMd ? "center" : "left"}} direction="row" >
              <img
                src="/assets/icons/location.png"
                width={upMd ? ICON_SIZE_LG : ICON_SIZE_SM}
                height={upMd ? ICON_SIZE_LG : ICON_SIZE_SM}
                alt="calendar_icon"
                style={{ marginRight: "16px" }}
              />
              <Stack direction="row">
                <b>Adresa:</b>
                <span style={{  marginLeft: "12px" }}>
                  <div>Lipí 14</div>
                  <div>Náchod</div>
                  <div>547 01</div>
                </span>
              </Stack>

            </Stack>
          </Stack>
          <Stack>

          </Stack>
        </Stack>
      </FocusPage>
    </Container>
  );
};

export default Contact;