import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      light: "#ECF7EC",
      main: "#DDF4EC",
      dark: "#A7D7C5",
    },
    secondary: {
      main: "#74B49B",
      // dark: "#5C8D89",
      dark: "#4D7B77",
    },
  },
  typography: {
    fontFamily: "Roboto, Arial, sans-serif",
  },
  breakpoints: {
    values: {
      xs: 280,
      sm: 450,
      md: 760,
      lg: 920,
      xl: 1400
    },
  }
});

export default theme;