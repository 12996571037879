import { Theme, useMediaQuery } from "@mui/material";

interface BreakpointsState {
  upMd: boolean;
  upLg: boolean;
  upSm: boolean;
  betweenSmMd: boolean;
  betweenXsSm: boolean;
}

export const useBreakpoints = (): BreakpointsState => {
  const upMd = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const upLg = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const upSm = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const betweenSmMd = useMediaQuery((theme: Theme) => theme.breakpoints.between("sm", "md"));
  const betweenXsSm = useMediaQuery((theme: Theme) => theme.breakpoints.between("xs", "sm"));
  return { upMd, upLg, upSm, betweenSmMd, betweenXsSm };
};
