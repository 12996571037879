import React, { PropsWithChildren } from "react";
import { Paper, Typography } from "@mui/material";
import { useBreakpoints } from "../hooks/useBreakpoint";

interface FocusPageProps {
  title: string;
}

const FocusPage: React.FC<PropsWithChildren<FocusPageProps>> = ({ title, children }) => {
  const { upMd } = useBreakpoints();

  return (
    <Paper
      sx={{
        m:"0 auto",
        zIndex: 10,
        padding: upMd ? "24px 40px" : "16px 16px",
        backgroundColor: "#ffffffd0",
        borderRadius: "5px",
        minHeight: "60vh",
        width: "100%"
      }}
    >
      <Typography textAlign="center" marginBottom="20px" variant="h4" component="h1" gutterBottom>
        {title}
      </Typography>
      {children}
    </Paper>
  );
};

export default FocusPage;