import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/system";
import Carousel from "react-material-ui-carousel";

const images = [
  "/assets/gallery/2.jpg",
  "/assets/gallery/3.jpg",
  "/assets/gallery/6.jpg",
  "/assets/gallery/5.jpg",
];
const StyledBox = styled(Box)({
  maxWidth: "100%",
  width: "100%",
  margin: "0 auto",
  "& .slick-slide img": {
    width: "100%",
    borderRadius: "10px",
  },
  "& .slick-prev, .slick-next": {
    zIndex: 1,
  },
});

const MyCarousel: React.FC = () => {
  return (
    <StyledBox>
      <Carousel
        duration={2000}
        autoPlay={true}
        interval={6000}
        animation="slide"
        sx={{zIndex:1}}
        indicatorIconButtonProps={{style: {display: "none"}}}
      >
        {images.map((image, index) => (
          <div key={index}>
            <img
              src={image}
              alt={`carousel-${index}`}
              style={{
                objectFit: "cover",
                height: "30vh",
                width: "100%",
              }}
            />
          </div>
        ))}
      </Carousel>
    </StyledBox>
  )
    ;
};

export default MyCarousel;